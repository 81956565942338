import {Controller} from 'stimulus'

export default class extends Controller {
  static targets = ['button', 'quantity']

  connect () {
    this.update()
  }

  update () {
    let missing = false
    this.quantityTargets.forEach((input) => {
      const expected = parseInt(input.dataset.expected)
      const value = parseInt(input.value)
      if (isNaN(value) || value < expected) {
        missing = true
      }
    })

    if (missing) {
      this.buttonTarget.classList.remove('btn-success')
      this.buttonTarget.classList.add('btn-warning')
    } else {
      this.buttonTarget.classList.remove('btn-warning')
      this.buttonTarget.classList.add('btn-success')
    }
  }
}
