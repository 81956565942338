import {Controller} from 'stimulus'
import $ from 'jquery'

export default class extends Controller {
  async open (event) {
    event.preventDefault()

    event.currentTarget.blur()

    const response = await fetch(event.currentTarget.href)
    const html = await response.text()

    $(html)
      .appendTo(document.body).modal()
      .on('hide.bs.modal', function (e) {
        const modals = $('body > [role=dialog]')
        if (modals[modals.length - 1] === this) {
          modals.eq(modals.length - 2).modal('show')
        }
      })
      .on('hidden.bs.modal', function (e) {
        const modals = $('body > [role=dialog]')
        if (modals.length > 1) {
          $(document.body).addClass('modal-open')
        }
        if (modals[modals.length - 1] === this) {
          $(this).modal('dispose').remove()
        }
      })

    $('body > [role=dialog]').modal('hide')
  }
}
