import {Controller} from 'stimulus'

export default class extends Controller {
  static targets = ['group', 'report']

  connect () {
    JSON.parse(this.data.get('data')).forEach((group) => {
      const groupOption = document.createElement('option')
      groupOption.innerHTML = group.label
      this.groupTarget.appendChild(groupOption)

      let activeReport

      group.reports.some((report) => {
        const link = document.createElement('a')
        link.href = report.url
        const isActive = window.location.href.startsWith(link.href)
        activeReport = report
        return isActive
      })

      if (activeReport != null) {
        group.reports.forEach((report) => {
          const reportOption = document.createElement('option')
          reportOption.innerHTML = report.label
          reportOption.value = report.url
          this.reportTarget.appendChild(reportOption)
        })
      }
    })
  }
}
