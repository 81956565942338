import {Controller} from 'stimulus'
import $ from 'jquery'

export default class extends Controller {
  connect () {
    setTimeout(() => {
      $(this.element).alert('close')
    }, 3000)
  }
}
