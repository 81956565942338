import {Controller} from 'stimulus'
import {Chart} from 'chart.js'

const BACKGROUND_COLORS = [
  'rgba(255, 99, 132, 0.2)',
  'rgba(54, 162, 235, 0.2)',
  'rgba(255, 206, 86, 0.2)',
  'rgba(75, 192, 192, 0.2)',
  'rgba(153, 102, 255, 0.2)',
  'rgba(255, 159, 64, 0.2)'
]

const BORDER_COLORS = [
  'rgba(255, 99, 132, 1)',
  'rgba(54, 162, 235, 1)',
  'rgba(255, 206, 86, 1)',
  'rgba(75, 192, 192, 1)',
  'rgba(153, 102, 255, 1)',
  'rgba(255, 159, 64, 1)'
]

export default class extends Controller {
  connect () {
    const data = JSON.parse(this.data.get('data'))

    new Chart(this.element, {
      type: 'bar',
      data: {
        labels: data.labels,
        datasets: data.datasets.map((dataset, i) => {
          return {
            label: dataset.label,
            data: dataset.data,
            backgroundColor: BACKGROUND_COLORS[i],
            borderColor: BORDER_COLORS[i],
            borderWidth: 1,
            grouped: false
          }
        })
      },
      options: {
        legend: {
          display: false
        },
        scales: {
          xAxes: [{
            stacked: true
          }],
          yAxes: [{
            stacked: true,
            ticks: {
              beginAtZero: true,
            }
          }]
        }
      }
    })
  }
}
