import {Controller} from 'stimulus'

export default class extends Controller {
  static targets = ['studentType', 'studentFields']

  connect () {
    this.change()
  }

  change () {
    console.log(this.studentTypeTarget.checked)
    this.studentFieldsTarget.style.display = this.studentTypeTarget.checked ? 'initial' : 'none'
  }
}
