import {Controller} from 'stimulus'
import $ from 'jquery'

export default class extends Controller {
  static targets = ['template', 'container', 'addSelect']

  connect () {
    $(this.addSelectTarget).on('select2:select', (e) => {
      const { id, text } = e.params.data
      const html =
        this.templateTarget.innerHTML
          .replace(/CHILD_INDEX/g, new Date().valueOf())
          .replace(/CHILD_FURNITURE_ITEM_ID/g, id)
          .replace(/CHILD_RENDERED/, text)
      this.containerTarget.insertAdjacentHTML('beforeend', html)

      $(this.addSelectTarget).val(null).trigger('change')
    })
  }

  remove (event) {
    event.preventDefault()

    const fields = event.target.closest('[data-target="furniture-order-lines.fields"]')
    fields.querySelector('input[name*="_destroy"]').value = 1
    fields.style.display = 'none'

    event.target.blur()
  }
}
