import {Controller} from 'stimulus'

export default class extends Controller {
  static targets = ['template', 'container']

  add (event) {
    event.preventDefault()

    const html = this.templateTarget.innerHTML.replace(/CHILD_INDEX/g, new Date().valueOf())
    this.containerTarget.insertAdjacentHTML('beforeend', html)

    event.target.blur()
  }

  remove (event) {
    event.preventDefault()

    const fields = event.target.closest('[data-target="nested-form.fields"]')
    const destroyInput = fields.querySelector('input[name*="_destroy"]')
    if (destroyInput != null) {
      destroyInput.value = 1
      fields.style.display = 'none'
    } else {
      fields.remove()
    }

    event.target.blur()
  }
}
