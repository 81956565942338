import {Controller} from 'stimulus'
import $ from 'jquery'

export default class extends Controller {
  search (event) {
    event.preventDefault()

    const action = event.target.action
    const query = $(event.target).serialize()

    this.load(`${action}?${query}`)
  }

  paginate (event) {
    event.preventDefault()

    const link = event.target.closest('a')
    if (link == null) return

    this.load(link.href)
  }

  async load (url) {
    const response = await fetch(url)
    const html = await response.text()

    this.element.closest('.modal').scrollTop = 0

    const $elem = $(html).find('[data-controller~=order-select-book]')
    $(this.element).replaceWith($elem)
  }

  add (event) {
    const item = event.target.closest('[data-target="order-select-book.item"]')
    const rendered = item.querySelector('[data-target="order-select-book.rendered"]')
    this.orderBooksController.add(item.dataset, rendered.innerHTML)
    event.currentTarget.classList.add('d-none')
    event.currentTarget.nextSibling.classList.remove('d-none')
  }

  get orderBooksController() {
    return this.application.controllers.find((controller) => {
      return controller.context.identifier === 'order-books'
    })
  }
}
