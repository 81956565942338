import {Controller} from 'stimulus'
import {Chart} from 'chart.js'

const BACKGROUND_COLORS = [
  'rgba(255, 99, 132, 0.2)',
  'rgba(54, 162, 235, 0.2)',
  'rgba(255, 206, 86, 0.2)',
  'rgba(75, 192, 192, 0.2)',
  'rgba(153, 102, 255, 0.2)',
  'rgba(255, 159, 64, 0.2)'
]

const BORDER_COLORS = [
  'rgba(255, 99, 132, 1)',
  'rgba(54, 162, 235, 1)',
  'rgba(255, 206, 86, 1)',
  'rgba(75, 192, 192, 1)',
  'rgba(153, 102, 255, 1)',
  'rgba(255, 159, 64, 1)'
]


export default class extends Controller {
  connect () {
    const data = JSON.parse(this.data.get('data'))

    const backgroundColors = []
    const borderColors = []

    for (let i = 0; i < data.length; i++) {
      backgroundColors.push(BACKGROUND_COLORS[i % BACKGROUND_COLORS.length])
      borderColors.push(BORDER_COLORS[i % BORDER_COLORS.length])
    }

    const type = this.data.get('type')

    new Chart(this.element, {
      type: type,
      data: {
        labels: data.map((d) => d[0]),
        datasets: [{
          data: data.map((d) => d[1]),
          backgroundColor: backgroundColors,
          borderColor: borderColors,
          borderWidth: 1
        }]
      },
      options: {
        tooltips: {
          callbacks: {
            title: function(tooltipItem, data) {
              return data['labels'][tooltipItem[0]['index']]
            },
            label: function(tooltipItem, data) {
              const label = data['datasets'][0]['data'][tooltipItem['index']]
              if (type === 'bar') return label

              const dataset = data['datasets'][0]
              const percent = Math.round((dataset['data'][tooltipItem['index']] / dataset["_meta"][this._chart.id]['total']) * 100)
              return label + ' (' + percent + '%)'
            }
          },
        },
        legend: {
          display: type !== 'bar'
        },
        scales: {
          yAxes: [{
            display: type === 'bar',
            ticks: {
              beginAtZero: true
            }
          }]
        }
      }
    })
  }
}
