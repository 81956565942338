import {Controller} from 'stimulus'

export default class extends Controller {
  static targets = ['kind', 'nameTextInput', 'nameSelect']

  connect () {
    this.update()
  }

  update () {
    const { value } = this.kindTarget
    let elemHide, elemShow
    if (value === 'book' || value === 'ebook') {
      elemHide = this.nameTextInputTarget
      elemShow = this.nameSelectTarget
    } else {
      elemHide = this.nameSelectTarget
      elemShow = this.nameTextInputTarget
    }
    elemHide.style.display = 'none'
    elemHide.querySelector('input, select').disabled = true
    elemShow.style.display = ''
    elemShow.querySelector('input, select').disabled = false
  }
}
