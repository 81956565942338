import {Controller} from 'stimulus'
import Turbolinks from 'turbolinks'
import $ from 'jquery'

export default class extends Controller {
  static targets = ['submit', 'spinner']

  connect () {
    const {dataset} = this.element
    dataset.remote = true

    this.element.addEventListener('ajax:beforeSend', (event) => {
      if (this.hasSubmitTarget) {
        this.submitTarget.disabled = true
      }

      if (this.hasSpinnerTarget) {
        this.spinnerTarget.classList.remove('d-none')
      }
    })

    this.element.addEventListener('ajax:success', (event) => {
      let [response,] = event.detail

      try {
        response = JSON.parse(response)
      } catch (e) {}

      if (response instanceof HTMLDocument || typeof response !== 'object') {
        const $form = $(response).find('[data-controller~=remote-form]')
        $(this.element).replaceWith($form)
      } else {
        if (response.redirect) {
          window.location.href = response.redirect
        } else if (response.refresh) {
          window.location.reload()
        }
      }
    })
  }
}
