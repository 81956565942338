import {Controller} from 'stimulus'
import Pikaday from 'pikaday'

export default class extends Controller {
  connect () {
    this.pikaday = new Pikaday({field: this.element, reposition: false, showDaysInNextAndPreviousMonths: true})
  }

  disconnect () {
    this.pikaday.destroy()
  }
}
