import {Controller} from 'stimulus'
import $ from 'jquery'

export default class extends Controller {
  static targets = ['template', 'container']

  add ({ id, ebookAvailable }, rendered) {
    const html = this.templateTarget.innerHTML
          .replace(/CHILD_INDEX/g, new Date().valueOf())
          .replace(/CHILD_BOOK_ID/g, id)
          .replace(/CHILD_RENDERED/, rendered)
    this.containerTarget.insertAdjacentHTML('beforeend', html)
    if (ebookAvailable != '1') {
      this.containerTarget.lastChild.querySelector('[data-target="order-books.ebook-check"]')?.remove()
    }
  }

  remove (event) {
    event.preventDefault()

    const fields = event.target.closest('[data-target="order-books.fields"]')
    fields.querySelector('input[name*="_destroy"]').value = 1
    fields.style.display = 'none'

    event.target.blur()
  }
}
