import {Controller} from 'stimulus'

export default class extends Controller {
  static targets = ['region', 'parish', 'list']

  connect () {
    this.filter()
  }

  change (e) {
    e.target.nextElementSibling.value = '1'
  }

  filter () {
    const region = this.regionTarget.value
    const parish = this.parishTarget.value
    Array.prototype.forEach.call(this.listTarget.children, (item) => {
      const visible = (!region || item.dataset.region == region) && (!parish || item.dataset.parish == parish)
      item.style.display = visible ? 'table-row' : 'none'
    })
  }
}
